/* eslint-disable react/jsx-no-literals */
import React, { useMemo } from 'react';
import {
  Popover,
  IconButton,
  createSystemFeedback,
  TSystemFeedbackService
} from 'react-style-guide';
import { CurrentUser, Dialog, EnvironmentUrls } from 'Roblox';
import { TranslateFunction, withTranslations, WithTranslationsProps } from 'react-utilities';
import ItemDetailsInfoService from '../services/itemDetailsInfoService';
import {
  TAssetItemDetails,
  TBundleItemDetails,
  TItemType,
  TUserItemPermissions
} from '../constants/types';
import { featureItemTranslationConfig } from '../translation.config';

type TItemDetailsContextMenuProps = {
  itemDetails: TAssetItemDetails | TBundleItemDetails;
  permissions: TUserItemPermissions;
};

type TContextMenuOption =
  | 'canDeleteFromInventory'
  | 'canReportItem'
  | 'canConfigureItem'
  | 'canSponsorItem'
  | 'canAddToShowcase'
  | 'canRemoveFromShowcase';

type TContextMenuOptionProps = {
  itemDetails: TAssetItemDetails | TBundleItemDetails;
  option: TContextMenuOption;
  translate: TranslateFunction;
  systemFeedbackService: TSystemFeedbackService;
};

function canDeleteFromInventory(
  itemDetails: TAssetItemDetails | TBundleItemDetails,
  permissions: TUserItemPermissions
) {
  if (permissions.isDeletableType) {
    if (itemDetails.creatorType === 'User' && itemDetails.creatorTargetId === 1) {
      return false;
    }
    if (itemDetails.owned) {
      return true;
    }
  }
  return false;
}

function genMenuOptions(
  itemDetails: TAssetItemDetails | TBundleItemDetails,
  permissions: TUserItemPermissions
): TContextMenuOption[] {
  const options: TContextMenuOption[] = [];
  if (!CurrentUser.isAuthenticated) return options;
  if (canDeleteFromInventory(itemDetails, permissions)) {
    options.push('canDeleteFromInventory');
  }
  if (permissions.canManageItem) {
    if (permissions.canConfigureItem && permissions.canViewConfigurePage) {
      options.push('canConfigureItem');
    }
    if (permissions.canSponsorItem) {
      options.push('canSponsorItem');
    }
  }
  if (permissions.isAllowedInShowcase && itemDetails.owned) {
    if (permissions.isInShowcase) {
      options.push('canRemoveFromShowcase');
    } else {
      options.push('canAddToShowcase');
    }
  }
  if (permissions.canReportItem) {
    options.push('canReportItem');
  }
  return options;
}

let isDeletingFromInventory = false;

function handleDeleteFromInventory(
  itemId: number,
  translate: TranslateFunction,
  systemFeedbackService: TSystemFeedbackService
) {
  Dialog.open({
    titleText: translate('Label.DeleteItem'),
    bodyContent: translate('Label.DeleteFromInventoryConfirm'),
    onAccept() {
      if (isDeletingFromInventory) {
        return;
      }

      isDeletingFromInventory = true;
      ItemDetailsInfoService.postDeleteItemFromInventory(itemId)
        .then(res => {
          if (res?.status === 200) {
            systemFeedbackService.success(translate('Response.RemovedFromInventory'));
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            systemFeedbackService.warning(
              translate('Response.FailedToDeleteFromInventory'),
              undefined,
              3000
            );
          }
        })
        .catch(() => {
          systemFeedbackService.warning(
            translate('Response.FailedToDeleteFromInventory'),
            undefined,
            3000
          );
        })
        .finally(() => {
          isDeletingFromInventory = false;
        });
    },
    xToCancel: true,
    allowHtmlContentInBody: true
  });
}

function handleAddToProfile(
  itemId: number,
  itemType: TItemType,
  translate: TranslateFunction,
  systemFeedbackService: TSystemFeedbackService
) {
  ItemDetailsInfoService.addToProfile(itemId, itemType)
    .then(res => {
      systemFeedbackService.success(translate('Response.AddedToProfile'));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch(() => {
      systemFeedbackService.warning(translate('Response.FailedToAddToProfile'), undefined, 3000);
    });
}

function handleRemoveFromProfile(
  itemId: number,
  itemType: TItemType,
  translate: TranslateFunction,
  systemFeedbackService: TSystemFeedbackService
) {
  ItemDetailsInfoService.removeFromProfile(itemId, itemType)
    .then(res => {
      systemFeedbackService.success(translate('Response.RemovedFromProfile'));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch(() => {
      systemFeedbackService.warning(
        translate('Response.FailedToRemoveFromProfile'),
        undefined,
        3000
      );
    });
}

function handleReportItem(
  itemId: number,
  itemType: TItemType,
  reporterId: string,
  translate: TranslateFunction,
  systemFeedbackService: TSystemFeedbackService
) {
  if (itemType === 'Asset') {
    const url = `/abusereport/asset?id=${itemId}&RedirectUrl=${encodeURIComponent(
      window.location.pathname + (window.location.search || '')
    )}`;
    window.location.replace(url);
  } else {
    ItemDetailsInfoService.postSubmitSafetyEvent(itemId, reporterId)
      .then(() => {
        systemFeedbackService.success(translate('Label.ReportSuccess'));
      })
      .catch(() => {
        systemFeedbackService.warning(translate('Label.ReportFailure'));
      });
  }
}

function handleSponsorItem(itemId: number, itemType: TItemType) {
  if (itemType === 'Asset') {
    const url = `/sponsored/catalog-assets/${itemId}/create`;
    window.location.replace(url);
  }
}

function ItemDetailsContextMenuOption({
  itemDetails,
  option,
  translate,
  systemFeedbackService
}: TContextMenuOptionProps) {
  if (option === 'canConfigureItem') {
    const url = `https://create.${EnvironmentUrls.domain}/dashboard/creations/${
      itemDetails.itemType === 'Asset' ? 'catalog' : 'bundle'
    }/${itemDetails.id}/configure`;
    return (
      <li>
        <a id='configure-item' href={url}>
          {translate('Action.Configure')}
        </a>
      </li>
    );
  }
  if (option === 'canReportItem') {
    return (
      <li>
        <button
          id='report-item'
          type='button'
          className='abuse-report-modal'
          onClick={() =>
            handleReportItem(
              itemDetails.id,
              itemDetails.itemType,
              CurrentUser.userId,
              translate,
              systemFeedbackService
            )
          }>
          {translate('Action.ReportItem')}
        </button>
      </li>
    );
  }
  if (option === 'canDeleteFromInventory') {
    return (
      <li>
        <button
          id='delete-item'
          type='button'
          onClick={() =>
            handleDeleteFromInventory(itemDetails.id, translate, systemFeedbackService)
          }>
          {translate('Action.Delete')}
        </button>
      </li>
    );
  }
  if (option === 'canAddToShowcase') {
    return (
      <li>
        <button
          id='add-to-profile'
          type='button'
          onClick={() =>
            handleAddToProfile(
              itemDetails.id,
              itemDetails.itemType,
              translate,
              systemFeedbackService
            )
          }>
          {translate('Action.AddToProfile')}
        </button>
      </li>
    );
  }
  if (option === 'canRemoveFromShowcase') {
    return (
      <li>
        <button
          id='remove-from-profile'
          type='button'
          onClick={() =>
            handleRemoveFromProfile(
              itemDetails.id,
              itemDetails.itemType,
              translate,
              systemFeedbackService
            )
          }>
          {translate('Action.RemoveFromProfile')}
        </button>
      </li>
    );
  }
  if (option === 'canSponsorItem') {
    return (
      <li>
        <button
          id='sponsor-item'
          type='button'
          onClick={() => handleSponsorItem(itemDetails.id, itemDetails.itemType)}>
          {translate('Action.SponsorItem')}
        </button>
      </li>
    );
  }
  return null;
}

export const ItemDetailsContextMenu = ({
  itemDetails,
  permissions,
  translate
}: TItemDetailsContextMenuProps & WithTranslationsProps): JSX.Element | null => {
  const menuOptions = useMemo(
    () => {
      // If ctx menu was rendered by backend, return an empty array (don't render anything)
      if (document.getElementById('item-context-menu')) return [];
      return genMenuOptions(itemDetails, permissions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemDetails?.id, itemDetails?.owned]
  );

  const [SystemFeedback, systemFeedbackService] = useMemo(() => createSystemFeedback(), []);

  if (!menuOptions?.length) {
    return <React.Fragment />;
  }

  return (
    <div className='item-context-menu my-ctx-menu'>
      <SystemFeedback />
      <Popover
        key={menuOptions.join(',')}
        id='game-instance-dropdown-menu'
        button={
          <IconButton
            iconName='more'
            size={IconButton.sizes.small}
            onClick={() => {
              /* function is required by typescript, but is added dynamically by the Popover parent */
            }}
          />
        }
        trigger='click'
        placement='bottom'>
        <ul className='dropdown-menu' role='menu'>
          {menuOptions.map(option => (
            <ItemDetailsContextMenuOption
              key={option}
              itemDetails={itemDetails}
              option={option}
              translate={translate}
              systemFeedbackService={systemFeedbackService}
            />
          ))}
        </ul>
      </Popover>
    </div>
  );
};
export default withTranslations(ItemDetailsContextMenu, featureItemTranslationConfig);
